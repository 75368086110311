import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "timChambers",
  "categories": ["testing"],
  "date": "2021-07-13",
  "path": "/blog/test-like-you-fly-1",
  "summary": "Introduction to Testing Like You Fly - TLYF",
  "title": "Test Like You Fly - Intro",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Test Like You Fly - Intro",
      "description": "Introduction to Testing Like You Fly - TLYF"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`I practice TLYF.`}</h2>
    <p>{`In contrast to all the other important test methodologies, "Test Like You Fly", or TLYF for short, emphasizes testing to find fundamental flaws in a system that will prevent it from performing the mission. Most testing methodologies strive to confirm that requirements - the input to our designs - are being met by the system as written.`}</p>
    <p>{`TLYF is all about confirming that the system - as a whole - will operate in the environment it is designed to operate, the environment we typically refer to as "live" or "production". It is vitally important to find what doesn’t perform as expected and to understand the reasons for this anomalous behavior, especially where such defects can degrade, cripple, or abruptly end a mission.`}</p>
    <p>{`During this 7-part series we will cover how to accomplish TLYF in detail in your own applications:`}</p>
    <ol>
      <li parentName="ol">{`Introduction (this post)`}</li>
      <li parentName="ol">{`Basically - the basics of testing like you fly`}</li>
      <li parentName="ol">{`The World Revolves Around You - how do you ensure a consistent environment externally`}</li>
      <li parentName="ol">{`Timing is (Almost) Everything - The impact time and timing have on our ability to TLYF`}</li>
      <li parentName="ol">{`What was your Intent? - Writing Intentional Code that minimizes inferences`}</li>
      <li parentName="ol">{`Logging and Failure Modes - what to ensure before liftoff in case bad things happen`}</li>
      <li parentName="ol">{`Fly What You Test - What to do when you liftoff`}</li>
    </ol>
    <h2>{`I think I already do this! So what makes TLYF different?`}</h2>
    <p>{`In our testing strategies we frequently attempt to isolate the component under test - large or small - from the rest of the system. We know what we want to test, and fine-grained or coarse, we test segments of the system. The larger the component, or the broader the test, the more we tend to try and prove it WILL work - work "as designed".`}</p>
    <p>{`TLYF comes at the system from the other direction. Testing Like You Fly is designed to as completely as possible drive the system as it will exist in production. It demonstrates that the mission of the system or application can achieve success, not that it merely meets requirements. All components are wired together and available in as real-world an environment as is possible.`}</p>
    <p>{`Testing Like You Fly originated in the aerospace industry, with the intention of mitigating risks by pre-playing all expected `}<em parentName="p">{`in flight`}</em>{` scenarios in advance of actual flight. Given the one and often only chance to make most flights a success, it is crucial to execute all happy paths and possible failure modes while on the ground. Here we use the term "mission" to represent the activities in the live environment an application is designed for.`}</p>
    <h2>{`TLYF is a Team Sport`}</h2>
    <p>{`This testing approach requires all hands on deck. Everyone from design, developers, support personnel, operations, etc. should be engaged in the process of being "LYF Architects". LYF Architects spend time identifying how the test environment - prior to `}<em parentName="p">{`liftoff`}</em>{` - can replicate the expectations of that live environment and identify gaps in testing to ensure success `}<em parentName="p">{`on the ground`}</em>{` means success `}<em parentName="p">{`in the air`}</em>{`.`}</p>
    <h2>{`What are the basic tenets of TLYF?`}</h2>
    <ol>
      <li parentName="ol">{`The system should NEVER experience expected operations, environments, stresses or their combinations for the first time DURING the mission.`}</li>
      <li parentName="ol">{`Do only smart things - do not subject the system to potentially damaging situations for which it is underprepared.`}</li>
      <li parentName="ol">{`TLYF does not replace other traditional forms of perceptive testing (e.g. environmental, stress, performance, unit or functional testing)`}</li>
      <li parentName="ol">{`When you cannot test like you fly - worry (or do risk management)`}</li>
    </ol>
    <h2>{`Why do we test?`}</h2>
    <p>{`The purpose of testing is NOT to prove that no flaws exist.`}</p>
    <ul>
      <li parentName="ul">{`It is not possible to prove that no flaws exist`}</li>
      <li parentName="ul">{`You can only prove that you haven't found any with the tests you have performed`}</li>
      <li parentName="ul">{`Systems always have flaws`}</li>
      <li parentName="ul">{`Ignorance - anywhere in the system - is also a flaw`}</li>
    </ul>
    <h2>{`Perspectives of Testing`}</h2>
    <p>{`There are two types of testing we encounter - `}<strong parentName="p">{`white box`}</strong>{` and `}<strong parentName="p">{`black box`}</strong>{`.`}</p>
    <p><strong parentName="p">{`White Box`}</strong>{` testing is detailed testing based on one premise - "I know how it works"`}</p>
    <p>{`The input for white box testing are edge cases and extremes - the testing is for specific behaviors. Any `}<strong parentName="p">{`code`}</strong>{` changes demand a re-test. Testing is performed in small increments. Detailed behavior is affirmed.`}</p>
    <p><strong parentName="p">{`Black Box`}</strong>{` testing leaves the system opaque to the tester. Input is typical input, and the outcomes measure the results. Any `}<strong parentName="p">{`requirements`}</strong>{` change demands a re-test. Testing is performed as end-to-end "day/weeks/years in the life" (DITL) - a `}<strong parentName="p">{`Total Operations Chain Test`}</strong>{` of all `}<strong parentName="p">{`First Time Activities`}</strong>{`. Testing needs to simulate activities with longer time frames through acceleration of time - as certainly monthly or once-a-year events still need tested.`}</p>
    <h2>{`What is a "Total Operations Chain Test"?`}</h2>
    <p>{`Pilots cannot test aircraft by starting from 30,000 feet. They have to taxi to the runway, takeoff, fly to a desired altitude, and then, and only then, can they experience the plane in its natural habitat. Likewise our applications do not magically start processing requests immediately. Their environment is provisioned, they are "booted", they establish connections to their surroundings.`}</p>
    <p>{`Only then are they prepared to "operate". During that operational period they are expected to handle on occasion a peak demand, and gracefully degrade. Should anything unanticipated occur they should log pertinent details for diagnosis, announce their "distress" and not collapse or fail miserably nor abruptly.`}</p>
    <p>{`The purpose of TLYF is to experience all of the above `}<em parentName="p">{`before launch`}</em>{`. With the comforting knowledge that whatever could happen has been tested, experienced, diagnosed, AND mitigated `}<em parentName="p">{`before launch`}</em>{`. Operate the entire system end-to-end through its full business lifecycle before it becomes critical to the business.`}</p>
    <h2>{`First Time Activities`}</h2>
    <p>{`The fundamental principle of TLYF is to perform activities for the first time pre-launch rather than during the actual mission, it follows that we must be cognizant of what those "first time" activities are. A "first-time" activity is not only the literal first time a discrete activity is performed, but is also the first time a repetitive set of activities (e.g., "nominal ops") is performed. Both versions of a first time activity are needed as the basis for the "days-in-the-life" (DITL) tests, with the second version necessary to flush out accumulation and asynchronous timing errors that need more than a single occurrence or cycle to allow this kind of flaw to manifest.`}</p>
    <h2>{`Flying Like You Test`}</h2>
    <p>{`Ok. You have done your best to TLYF. Your application is humming along nicely doing what it does best - serving your customer's needs - fulfilling a mission. And over the horizon, you see a scenario brewing that you did not anticipate - a new set of circumstances, a change in the business needs, data that is outside the expected value ranges, etc. What do you do?`}</p>
    <p>{`What you don't do is test in production. Of course not. You would not do that. Standing back you realize that this new scenario was not considered in your TLYF test environment. It may be obvious that you need to introduce this scenario into a full TLYF lifecycle test, in all its nuance, to a) discover how the mission will transpire, and b) to assess what adjustments need to be made to support this new scenario. The critical step in Fly Like You Test is appreciating that you should never "fly" any scenario in the production environment without first testing it.`}</p>
    <h3>{`TLYF and then FLYT.`}</h3>
    <p>{`How do I TLYF? Next up in this series: The Basics of Testing Like You Fly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      